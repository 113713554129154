'use client';

import React, { type ReactNode } from 'react';
import {
  OptimizelySubitoProvider,
  createOptimizelyInstance,
  zeroUUID,
} from '@sbt-web/houston-wrapper';
import { ENV, isBrowser } from '@sbt-web/utils';
import { getTrackingInstance } from 'helpers/PulseTracking';

// Client / Server without checks
const optimizely = createOptimizelyInstance(
  process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV
);

const Provider = ({ children }: { children: ReactNode }) => {
  return (
    <OptimizelySubitoProvider
      optimizely={optimizely}
      // on server pass "zero'd" ID
      environmentId={getTrackingInstance()?.getEnvironmentId() ?? zeroUUID}
      isServerSide={!isBrowser()}
    >
      {children}
    </OptimizelySubitoProvider>
  );
};

export default Provider;

'use client';

import SharedFooter from '@sbt-web/footer';
import { clickFooterTracking } from 'helpers/PulseTracking';

const Footer = () => {
  return (
    <SharedFooter
      type="full"
      assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
      onClickItem={clickFooterTracking}
    />
  );
};

export default Footer;

'use client';

import React from 'react';

import { didomiScriptElements } from '@sbt-web/didomi';
import { ENV } from '@sbt-web/utils';

function DidomiWrapper() {
  return (
    <>
      {didomiScriptElements({
        env: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
      })}
    </>
  );
}

export default DidomiWrapper;

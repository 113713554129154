import(/* webpackMode: "eager" */ "/app/node_modules/@sbt-web/ui/dist/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@sbt-web/footer/dist/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@sbt-web/header/dist/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/layout.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/didomi-wrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataLayerReadyProvider"] */ "/app/src/components/SubitoScripts/DataLayerContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubitoScripts"] */ "/app/src/components/SubitoScripts/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/WebVitals/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/optimizely.tsx");

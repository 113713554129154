'use client';

import { getPublicUser, PublicUser } from '@sbt-web/auth';
import SharedHeader from '@sbt-web/header';
import {
  clickHeaderTracking,
  clickNewAdTrackingFromMenu,
  getTrackingInstance,
} from 'helpers/PulseTracking';
import React, { useEffect, useState } from 'react';

const emptyUser = new PublicUser('', '', '');

const Header = () => {
  const [user, setUser] = useState<PublicUser>(emptyUser);

  useEffect(() => {
    const publicUser = getPublicUser();

    if (publicUser != null) {
      setUser(publicUser);
    }
  }, []);

  return (
    <SharedHeader
      section="home"
      user={user}
      urlPrefixes={{
        baseURL: process.env.NEXT_PUBLIC_ENV_ORIGIN,
        assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE,
        reservedArea: process.env.NEXT_PUBLIC_RESERVEDAREA_BASE,
        phpBase: process.env.NEXT_PUBLIC_PHP_BASE,
        shopBase: process.env.NEXT_PUBLIC_IMPRESAPIU_BASE,
      }}
      onClickHandlers={{
        adButton: clickNewAdTrackingFromMenu,
        onClickItem: clickHeaderTracking,
      }}
      pulseInstance={getTrackingInstance()}
    />
  );
};

export default Header;
